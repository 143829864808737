@import "../../../config/variables.scss";

.categories-pills {
  display: none;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-wrap: wrap;
    margin-top: $main-padding;
    margin-bottom: $main-padding * 2;
  }
}

.categories-select {
  display: block;
  margin-bottom: $main-padding * 2;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: none;
  }
}
