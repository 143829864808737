@import "../../../config/variables";

.video-container {
  figcaption {
    text-align: center;
    font-weight: 600;
    margin-top: $main-padding / 2;
  }
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
