@import "../../../config/variables";

.section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $main-padding * 2;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex-direction: row;
  }
}
