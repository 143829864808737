@import "../../../config/variables.scss";

.project-review {
  margin-bottom: $main-padding;
  height: 100%;
  padding: $main-padding;

  @media screen and (min-width: map-get($breakpoints, md)) {
    padding: $main-padding * 3;
  }
}

.project-review-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 0.9rem;

  @media screen and (min-width: map-get($breakpoints, md)) {
    font-size: 1.1rem;
  }

  .review {
    margin-bottom: $main-padding * 0.5;

    @media screen and (min-width: map-get($breakpoints, md)) {
      margin-bottom: $main-padding * 1.5;
    }

    p {
      &:first-child {
        &:before {
          content: "„";
        }
      }
      &:last-child {
        &:after {
          content: "“";
        }
      }
    }
  }
}
