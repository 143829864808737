@import "../../../config/variables.scss";

.clients {
  display: flex;
  flex-wrap: wrap;

  .client {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $main-padding $main-padding;

    img {
      width: 100%;
    }
  }
  @media screen and (min-width: map-get($breakpoints, sm)) {
    .client {
      padding: $main-padding * 2 $main-padding * 3;
    }
  }
  @media screen and (min-width: map-get($breakpoints, md)) {
    .client {
      flex: 0 0 25%;
    }
  }
}
