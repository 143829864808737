@import "../../../config/variables.scss";

.competence {
  position: relative;
  border-width: 5px;
  border-style: solid;
  height: ($max-width - 8 * $main-padding) / 4;

  cursor: pointer;
  transition: background-color $transition-time;
  margin-bottom: $main-padding;

  &:hover,
  &.active {
    background-color: rgba(#fff, 0.5);
  }

  .competence-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .competence-title-content {
    font-size: 2rem;
    font-weight: 700;
  }

  &.white {
    border-color: #fff;
    .competence-title-content {
      color: $color-primary;
      box-shadow: inset 0 -0.175em $color-primary;
    }
  }

  &.black {
    border-color: $color-text-dark;
    .competence-title-content {
      color: $color-text-dark;
      box-shadow: inset 0 -0.175em $color-text-dark;
    }
  }

  &.gold {
    border-color: $color-gold;
    .competence-title-content {
      color: $color-gold;
      box-shadow: inset 0 -0.175em $color-gold;
    }
  }

  &.primary {
    border-color: $color-primary;
    .competence-title-content {
      color: $color-primary;
      box-shadow: inset 0 -0.175em $color-primary;
    }
  }
}
