@import "../../../config/variables";

.contact-info {
  background-color: $color-secondary;
  padding-top: $main-padding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex-direction: row;
    padding-bottom: $main-padding;
  }

  .hero-content {
    margin-bottom: $main-padding * 2;
  }

  .hero-description {
    margin-bottom: $main-padding;
  }

  .hero-background {
    width: 100%;
  }

  .contact-title {
    margin-bottom: $main-padding;
    font-size: 1.4rem;
  }

  .contact-social-title {
    margin-bottom: $main-padding;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .contact-name {
    margin-bottom: $main-padding/2;
  }

  .contact-section {
    margin-bottom: $main-padding;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    min-height: 80vh;
    background-color: transparent;

    .hero-content {
      margin-bottom: 0;
    }

    .hero-background {
      position: absolute;
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto;
      right: 0;
      bottom: 0;
      z-index: -1;
      display: block;
    }

    .hero-content {
      align-items: flex-start;
    }
  }
}
