@import "../../../config/variables.scss";

.buttons-container {
  display: flex;
  justify-content: flex-start;

  &.center {
    justify-content: center;
  }
}
