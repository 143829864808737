@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap&subset=latin-ext);
.button {
  padding: 13.33333px 40px;
  margin: 10px 0;
  color: #20211b;
  border: none;
  border-radius: 20px;
  font-weight: 700;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  background-color: #fff;
  -webkit-transition: 0.3s background-color, 0.3s border-color;
  transition: 0.3s background-color, 0.3s border-color; }
  .button:link {
    color: #20211b; }
  .button:visited {
    color: #20211b; }
  .button.auto {
    align-self: flex-start; }
  .button.button-primary {
    background: #1100ca;
    color: #fff; }
    .button.button-primary:hover {
      background: #4231ff; }
  .button.button-secondary {
    background: #f8e001; }
    .button.button-secondary:hover {
      background: #feef61; }
  .button.disabled, .button:disabled {
    background: #aaa;
    color: #fff;
    cursor: not-allowed; }
    .button.disabled:hover, .button:disabled:hover {
      background: #aaa;
      color: #fff; }
  .button .button-arrow {
    font-size: 0.75em;
    margin: 0 5px; }

.hero-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 1.2;
  display: inline-block;
  white-space: pre-wrap; }
  .hero-title .hero-title-content {
    box-shadow: inset 0 -0.175em #fff; }
  .hero-title.accent .hero-title-content {
    box-shadow: inset 0 -0.175em #f8e001; }
  .hero-title.project {
    margin-top: 40px;
    margin-bottom: 0; }
    .hero-title.project .hero-title-content {
      color: #fff;
      box-shadow: inset 0 -0.175em #f8e001; }
  @media screen and (min-width: 768px) {
    .hero-title {
      font-size: 4rem; }
      .hero-title.hero-title-project {
        margin-top: 100px; } }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: #fff;
  opacity: 1;
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s; }
  .loader .indicator {
    width: 0;
    height: 100vh;
    background-color: #f8e001; }
  .loader .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 700; }
  .loader.fading {
    opacity: 0; }

.brand-logo {
  flex: 1 1;
  height: 100px; }
  .brand-logo img {
    height: 100%;
    max-width: 100%; }

.menu-toggle .hamburger-inner, .menu-toggle .hamburger-inner:before, .menu-toggle .hamburger-inner:after {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: #20211b; }

.menu-toggle {
  z-index: 2;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer; }
  .menu-toggle .hamburger-inner {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: 0.3s background-color;
    transition: 0.3s background-color; }
    .menu-toggle .hamburger-inner:before, .menu-toggle .hamburger-inner:after {
      content: "";
      -webkit-transition: 0.3s -webkit-transform;
      transition: 0.3s -webkit-transform;
      transition: 0.3s transform;
      transition: 0.3s transform, 0.3s -webkit-transform; }
    .menu-toggle .hamburger-inner:before {
      top: -10px; }
    .menu-toggle .hamburger-inner:after {
      bottom: -10px; }
  .menu-toggle.toggled .hamburger-inner {
    background-color: transparent; }
    .menu-toggle.toggled .hamburger-inner:before {
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      background-color: #fffffe;
      top: 0; }
    .menu-toggle.toggled .hamburger-inner:after {
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      background-color: #fffffe;
      bottom: 0; }
  @media screen and (min-width: 768px) {
    .menu-toggle {
      display: none; } }

.section-title {
  font-size: 1.75rem;
  font-weight: 700; }
  .section-title.accent {
    box-shadow: inset 0 -0.175em #f8e001; }
  @media screen and (min-width: 768px) {
    .section-title {
      font-size: 2.5rem; } }

.social-buttons {
  display: flex; }

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #20211b;
  color: #fffffe;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 1.5rem;
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color; }
  .social-button:link, .social-button:visited, .social-button:hover, .social-button:active {
    color: #fffffe; }
  .social-button:hover {
    background-color: #565949; }

.scroll-top-container {
  text-align: center;
  padding-top: 20px; }

.scroll-top {
  border: none;
  padding: 10px;
  color: #20211b;
  background: transparent;
  outline: none;
  cursor: pointer; }

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .box {
      padding: 120px; } }
  .box .box-background {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: -1;
    display: block; }
  .box.box-primary {
    background-color: rgba(101, 101, 101, 0.5);
    color: #fffffe; }
  .box.box-secondary {
    background-color: #f8e001; }
  .box.box-left {
    align-items: flex-start; }
  .box .box-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 30px; }
  .box .box-content {
    font-size: 1.2rem; }
  .box .box-link {
    color: #1100ca;
    font-weight: 700;
    font-size: 1.75rem; }
    .box .box-link:hover {
      color: #4231ff; }
    .box .box-link .link-content {
      border-bottom: 3px solid #1100ca; }
  .box .box-arrow {
    font-size: 0.75em; }

.box-container {
  display: flex;
  flex-direction: column; }
  .box-container .box {
    flex: 1 1; }
  @media screen and (min-width: 768px) {
    .box-container {
      flex-direction: row; }
      .box-container ul {
        flex-direction: row;
        background-color: transparent; } }

.buttons-container {
  display: flex;
  justify-content: flex-start; }
  .buttons-container.center {
    justify-content: center; }

.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0px auto; }

.footer {
  padding: 60px 0;
  padding-top: 80px; }

.footer-section {
  margin-bottom: 20px; }

.footer-section-title {
  font-size: 0.9rem;
  margin-bottom: 20px;
  text-transform: uppercase; }

.top-header {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2; }
  .top-header.static {
    position: relative; }

.top-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px 0;
  position: relative; }

.navigation {
  -webkit-transition: 0.3s all;
  transition: 0.3s all; }
  .navigation ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 0;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: 0.3s all;
    transition: 0.3s all; }
  .navigation li a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    padding: 10px 5px;
    margin: 0 5px;
    display: block; }
    .navigation li a:link {
      color: #20211b; }
    .navigation li a:visited {
      color: #20211b; }
    .navigation li a:hover {
      color: #3b3d32; }
    .navigation li a:active {
      color: #1100ca; }
    .navigation li a.active {
      font-weight: 700;
      color: #1100ca; }
  @media screen and (max-width: 767px) {
    .navigation {
      position: absolute;
      width: 0;
      height: 100vh;
      top: 0;
      right: -20px;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.5);
      background-color: #080064; }
      .navigation ul {
        padding-top: 100px;
        opacity: 0; }
      .navigation li a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 400;
        padding: 10px 5px;
        margin: 0 5px;
        display: block; }
        .navigation li a:link {
          color: #fffffe; }
        .navigation li a:visited {
          color: #fffffe; }
        .navigation li a:hover {
          color: white; }
        .navigation li a:active {
          color: #fffffe; }
        .navigation li a.active {
          font-weight: 700;
          color: #fffffe; }
      .navigation.toggled {
        display: block;
        width: 50vw; }
        .navigation.toggled ul {
          padding-left: 40px;
          padding-right: 20px;
          opacity: 1;
          width: auto; } }
  @media screen and (min-width: 768px) {
    .navigation {
      display: block; }
      .navigation ul {
        flex-direction: row; } }

.row {
  height: 100%; }

.page-section {
  padding: 40px 0; }

.section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .section-header {
      flex-direction: row; } }

.hero {
  background-color: #f8e001;
  padding-top: 140px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .hero {
      flex-direction: column;
      padding-bottom: 140px; } }
  .hero .hero-background {
    width: 100%; }
  .hero .hero-content {
    margin-bottom: 40px; }
  .hero .hero-description {
    margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .hero {
      min-height: 100vh;
      background-color: transparent; }
      .hero .hero-content {
        margin-bottom: 0; }
      .hero .hero-background {
        position: absolute;
        background-color: #f8e001;
        min-height: 100%;
        min-width: 100%;
        height: auto;
        width: auto;
        left: 0;
        top: 0;
        z-index: -1;
        display: block; }
      .hero .hero-content {
        align-items: flex-start; } }

.project-tile {
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 250px;
  margin-bottom: 40px; }
  .project-tile:hover .project-tile-content {
    opacity: 1; }

.project-tile-content {
  position: absolute;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 50%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: rgba(248, 224, 1, 0.9);
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  word-break: break-word; }
  @media screen and (min-width: 768px) {
    .project-tile-content {
      opacity: 0;
      height: 100%;
      top: 0;
      -webkit-transform: none;
              transform: none; } }
  .project-tile-content .project-title {
    font-weight: 700;
    font-size: 1.1rem;
    color: #20211b; }
  .project-tile-content .project-client {
    color: #20211b; }
  .project-tile-content .client-arrow {
    margin-left: 5px;
    font-size: 0.8rem; }

.project-hero {
  background-color: rgba(17, 0, 202, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px 0; }
  .project-hero .hero-subtitle {
    display: block;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 40px;
    white-space: pre-wrap; }
  .project-hero .hero-description {
    margin-bottom: 20px; }
  .project-hero .hero-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    display: block;
    background-size: cover;
    background-position: center; }
  .project-hero .video-container {
    position: absolute;
    top: 50%;
    left: -100%;
    width: 300%;
    height: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding-bottom: 56.25%;
    /* 16:9 */ }
    .project-hero .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  @media screen and (min-width: 768px) {
    .project-hero {
      padding: 40px 0; }
      .project-hero .hero-content {
        align-items: flex-start; }
      .project-hero .project-info {
        padding-left: 80px; }
      .project-hero .hero-subtitle {
        font-size: 4rem;
        line-height: 4rem;
        margin-bottom: 100px; } }

.project-description {
  max-width: 800px; }
  .project-description:last-of-type {
    margin-bottom: 60px; }
  @media screen and (min-width: 768px) {
    .project-description {
      padding-left: 80px; } }

.project-photo {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-bottom: 20px; }

@charset "UTF-8";
.project-review {
  margin-bottom: 20px;
  height: 100%;
  padding: 20px; }
  @media screen and (min-width: 920px) {
    .project-review {
      padding: 60px; } }

.project-review-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 0.9rem; }
  @media screen and (min-width: 920px) {
    .project-review-content {
      font-size: 1.1rem; } }
  .project-review-content .review {
    margin-bottom: 10px; }
    @media screen and (min-width: 920px) {
      .project-review-content .review {
        margin-bottom: 30px; } }
    .project-review-content .review p:first-child:before {
      content: "„"; }
    .project-review-content .review p:last-child:after {
      content: "“"; }

.project-category {
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 20px;
  flex: 0 1 100%; }
  .project-category:link {
    color: #20211b; }
  .project-category:visited {
    color: #20211b; }
  .project-category:hover {
    color: #3b3d32;
    text-decoration: underline; }
  .project-category:active {
    color: #1100ca; }
  .project-category.active {
    color: #1100ca;
    font-weight: 700;
    background-color: #e6e8e0; }
  @media screen and (min-width: 768px) {
    .project-category {
      flex: 0 1 auto; } }

.categories-pills {
  display: none; }
  @media screen and (min-width: 768px) {
    .categories-pills {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 40px; } }

.categories-select {
  display: block;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .categories-select {
      display: none; } }

.video-container figcaption {
  text-align: center;
  font-weight: 600;
  margin-top: 10px; }

.video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.competence {
  position: relative;
  border-width: 5px;
  border-style: solid;
  height: 260px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin-bottom: 20px; }
  .competence:hover, .competence.active {
    background-color: rgba(255, 255, 255, 0.5); }
  .competence .competence-title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center; }
  .competence .competence-title-content {
    font-size: 2rem;
    font-weight: 700; }
  .competence.white {
    border-color: #fff; }
    .competence.white .competence-title-content {
      color: #1100ca;
      box-shadow: inset 0 -0.175em #1100ca; }
  .competence.black {
    border-color: #20211b; }
    .competence.black .competence-title-content {
      color: #20211b;
      box-shadow: inset 0 -0.175em #20211b; }
  .competence.gold {
    border-color: #f7b004; }
    .competence.gold .competence-title-content {
      color: #f7b004;
      box-shadow: inset 0 -0.175em #f7b004; }
  .competence.primary {
    border-color: #1100ca; }
    .competence.primary .competence-title-content {
      color: #1100ca;
      box-shadow: inset 0 -0.175em #1100ca; }

.competence-name {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px; }
  .competence-name.gold {
    color: #f7b004; }
  .competence-name.primary {
    color: #1100ca; }

.competence-description {
  max-width: 800px;
  margin: 0 auto; }
  .competence-description.bind {
    background-color: #fff;
    margin: 20px -20px;
    margin-top: 0;
    padding: 20px; }
    @media screen and (min-width: 768px) {
      .competence-description.bind {
        display: none; } }

.competences-hero {
  background-color: #f8e001;
  padding-top: 140px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden; }
  .competences-hero .hero-description {
    margin-bottom: 20px; }
  .competences-hero .hero-background {
    display: none; }
  .competences-hero .competence-indicator-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 1200px;
    width: 100%; }
  .competences-hero .competence-indicator {
    display: none;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 60px solid #fff;
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    -webkit-transition: left 0.3s;
    transition: left 0.3s; }
    .competences-hero .competence-indicator.competence-1 {
      left: 12.5%; }
    .competences-hero .competence-indicator.competence-2 {
      left: 37.5%; }
    .competences-hero .competence-indicator.competence-3 {
      left: 62.5%; }
    .competences-hero .competence-indicator.competence-4 {
      left: 87.5%; }
  @media screen and (min-width: 768px) {
    .competences-hero {
      min-height: 100vh;
      background-color: rgba(248, 224, 1, 0.75); }
      .competences-hero .hero-background {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        display: block; }
      .competences-hero .hero-content {
        align-items: flex-start;
        margin-bottom: 80px; } }
  @media screen and (min-width: 920px) {
    .competences-hero .competence-indicator {
      display: block; } }

.competences {
  display: none; }
  @media screen and (min-width: 768px) {
    .competences {
      display: block; } }

.clients {
  display: flex;
  flex-wrap: wrap; }
  .clients .client {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px; }
    .clients .client img {
      width: 100%; }
  @media screen and (min-width: 768px) {
    .clients .client {
      padding: 40px 60px; } }
  @media screen and (min-width: 920px) {
    .clients .client {
      flex: 0 0 25%; } }

.about-hero {
  padding-top: 140px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible; }
  .about-hero .hero-description {
    margin-bottom: 20px; }
  .about-hero .hero-background {
    display: none; }
  @media screen and (min-width: 768px) {
    .about-hero {
      min-height: 70vh; }
      .about-hero .hero-background {
        position: absolute;
        min-height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        display: block; }
      .about-hero .hero-description {
        margin-left: 20px; }
      .about-hero .hero-content {
        align-items: flex-start; } }

.member {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column; }
  .member .member-photo {
    flex: 0 0 33%; }
    .member .member-photo img {
      width: 100%; }
  .member .member-info {
    display: flex;
    flex-direction: column; }
  .member .member-name {
    display: flex;
    flex-direction: column; }
  .member .member-about {
    margin-top: 20px;
    text-align: justify; }
  @media screen and (min-width: 768px) {
    .member {
      flex-direction: row;
      margin-bottom: 100px; }
      .member .member-info {
        padding: 0 60px 0; }
      .member .member-about {
        margin-top: 60px; }
      .member.alternate {
        flex-direction: row-reverse; }
        .member.alternate .member-info {
          text-align: right; } }

.contact-info {
  background-color: #f8e001;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .contact-info {
      flex-direction: row;
      padding-bottom: 20px; } }
  .contact-info .hero-content {
    margin-bottom: 40px; }
  .contact-info .hero-description {
    margin-bottom: 20px; }
  .contact-info .hero-background {
    width: 100%; }
  .contact-info .contact-title {
    margin-bottom: 20px;
    font-size: 1.4rem; }
  .contact-info .contact-social-title {
    margin-bottom: 20px;
    font-size: 0.9rem;
    text-transform: uppercase; }
  .contact-info .contact-name {
    margin-bottom: 10px; }
  .contact-info .contact-section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; }
  @media screen and (min-width: 768px) {
    .contact-info {
      min-height: 80vh;
      background-color: transparent; }
      .contact-info .hero-content {
        margin-bottom: 0; }
      .contact-info .hero-background {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        height: auto;
        right: 0;
        bottom: 0;
        z-index: -1;
        display: block; }
      .contact-info .hero-content {
        align-items: flex-start; } }

* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 14px; }

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5; }

h3 {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 10px; }

a {
  text-decoration: none;
  font-weight: 400;
  -webkit-transition: 0.3s color;
  transition: 0.3s color; }
  a:link {
    color: #1100ca; }
  a:visited {
    color: #1100ca; }
  a:hover {
    color: #1500fd; }
  a:active {
    color: #1100ca; }

em {
  font-style: italic; }

strong {
  font-weight: 700; }

p {
  margin-bottom: 20px; }

.hidden {
  display: none; }

.row {
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .row {
      margin-left: -20px;
      margin-right: -20px; } }
  .row [class*="col-"] {
    flex: 0 1 100%;
    padding-left: 5px;
    padding-right: 5px;
    min-height: 1px;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .row [class*="col-"] {
        padding-left: 20px;
        padding-right: 20px; } }
    .row [class*="col-"].vertical {
      flex-direction: row;
      justify-content: space-between; }
  .row .col-1 {
    flex: 0 1 8.33333%; }
  .row .col-2 {
    flex: 0 1 16.66667%; }
  .row .col-3 {
    flex: 0 1 25%; }
  .row .col-4 {
    flex: 0 1 33.33333%; }
  .row .col-5 {
    flex: 0 1 41.66667%; }
  .row .col-6 {
    flex: 0 1 50%; }
  .row .col-7 {
    flex: 0 1 58.33333%; }
  .row .col-8 {
    flex: 0 1 66.66667%; }
  .row .col-9 {
    flex: 0 1 75%; }
  .row .col-10 {
    flex: 0 1 83.33333%; }
  .row .col-11 {
    flex: 0 1 91.66667%; }
  .row .col-12 {
    flex: 0 1 100%; }
  @media screen and (min-width: 576px) {
    .row .col-xs-1 {
      flex: 0 1 8.33333%; }
    .row .col-xs-2 {
      flex: 0 1 16.66667%; }
    .row .col-xs-3 {
      flex: 0 1 25%; }
    .row .col-xs-4 {
      flex: 0 1 33.33333%; }
    .row .col-xs-5 {
      flex: 0 1 41.66667%; }
    .row .col-xs-6 {
      flex: 0 1 50%; }
    .row .col-xs-7 {
      flex: 0 1 58.33333%; }
    .row .col-xs-8 {
      flex: 0 1 66.66667%; }
    .row .col-xs-9 {
      flex: 0 1 75%; }
    .row .col-xs-10 {
      flex: 0 1 83.33333%; }
    .row .col-xs-11 {
      flex: 0 1 91.66667%; }
    .row .col-xs-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 768px) {
    .row .col-sm-1 {
      flex: 0 1 8.33333%; }
    .row .col-sm-2 {
      flex: 0 1 16.66667%; }
    .row .col-sm-3 {
      flex: 0 1 25%; }
    .row .col-sm-4 {
      flex: 0 1 33.33333%; }
    .row .col-sm-5 {
      flex: 0 1 41.66667%; }
    .row .col-sm-6 {
      flex: 0 1 50%; }
    .row .col-sm-7 {
      flex: 0 1 58.33333%; }
    .row .col-sm-8 {
      flex: 0 1 66.66667%; }
    .row .col-sm-9 {
      flex: 0 1 75%; }
    .row .col-sm-10 {
      flex: 0 1 83.33333%; }
    .row .col-sm-11 {
      flex: 0 1 91.66667%; }
    .row .col-sm-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 920px) {
    .row .col-md-1 {
      flex: 0 1 8.33333%; }
    .row .col-md-2 {
      flex: 0 1 16.66667%; }
    .row .col-md-3 {
      flex: 0 1 25%; }
    .row .col-md-4 {
      flex: 0 1 33.33333%; }
    .row .col-md-5 {
      flex: 0 1 41.66667%; }
    .row .col-md-6 {
      flex: 0 1 50%; }
    .row .col-md-7 {
      flex: 0 1 58.33333%; }
    .row .col-md-8 {
      flex: 0 1 66.66667%; }
    .row .col-md-9 {
      flex: 0 1 75%; }
    .row .col-md-10 {
      flex: 0 1 83.33333%; }
    .row .col-md-11 {
      flex: 0 1 91.66667%; }
    .row .col-md-12 {
      flex: 0 1 100%; } }
  @media screen and (min-width: 1200px) {
    .row .col-lg-1 {
      flex: 0 1 8.33333%; }
    .row .col-lg-2 {
      flex: 0 1 16.66667%; }
    .row .col-lg-3 {
      flex: 0 1 25%; }
    .row .col-lg-4 {
      flex: 0 1 33.33333%; }
    .row .col-lg-5 {
      flex: 0 1 41.66667%; }
    .row .col-lg-6 {
      flex: 0 1 50%; }
    .row .col-lg-7 {
      flex: 0 1 58.33333%; }
    .row .col-lg-8 {
      flex: 0 1 66.66667%; }
    .row .col-lg-9 {
      flex: 0 1 75%; }
    .row .col-lg-10 {
      flex: 0 1 83.33333%; }
    .row .col-lg-11 {
      flex: 0 1 91.66667%; }
    .row .col-lg-12 {
      flex: 0 1 100%; } }

