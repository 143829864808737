@import "../../../config/variables";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: $main-padding * 3;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding: $main-padding * 6;
  }

  .box-background {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: block;
  }

  &.box-primary {
    background-color: rgba(desaturate($color-primary, 100%), 0.5);
    color: $color-text-light;
  }
  &.box-secondary {
    background-color: $color-secondary;
  }

  &.box-left {
    align-items: flex-start;
  }
  .box-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: $main-padding * 1.5;
  }
  .box-content {
    font-size: 1.2rem;
  }
  .box-link {
    color: $color-primary;
    font-weight: 700;
    font-size: 1.75rem;

    &:hover {
      color: lighten($color-primary, 20%);
    }

    .link-content {
      border-bottom: 3px solid $color-primary;
    }
  }

  .box-arrow {
    font-size: 0.75em;
  }
}
