@import "../../../config/variables";

.box-container {
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex-direction: row;

    ul {
      flex-direction: row;
      background-color: transparent;
    }
  }
}
