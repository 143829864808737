@import "../../../config/variables";

.button {
  padding: $main-padding/1.5 $main-padding * 2;
  margin: $main-padding / 2 0;
  color: $color-text-dark;
  border: none;
  border-radius: $main-padding;
  font-weight: 700;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  background-color: #fff;

  transition: $transition-time background-color, $transition-time border-color;

  &:link {
    color: $color-text-dark;
  }

  &:visited {
    color: $color-text-dark;
  }

  &.auto {
    align-self: flex-start;
  }

  &.button-primary {
    background: $color-primary;
    color: #fff;
    &:hover {
      background: lighten($color-primary, 20%);
    }
  }

  &.button-secondary {
    background: $color-secondary;
    &:hover {
      background: lighten($color-secondary, 20%);
    }
  }

  &.disabled,
  &:disabled {
    background: #aaa;
    color: #fff;
    cursor: not-allowed;

    &:hover {
      background: #aaa;
      color: #fff;
    }
  }

  .button-arrow {
    font-size: 0.75em;
    margin: 0 $main-padding / 4;
  }
}
