@import "../../../config/variables";

.section-title {
  font-size: 1.75rem;
  font-weight: 700;

  &.accent {
    box-shadow: inset 0 -0.175em $color-secondary;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    font-size: 2.5rem;
  }
}
