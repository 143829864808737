@import "../../../config/variables";

%hamburger-bar {
  position: absolute;
  display: block;
  height: $hamburger-bar-height;
  width: 100%;
  border-radius: 1px;
  background-color: $color-text-dark;
}

.menu-toggle {
  z-index: 2;
  width: $main-padding * 2;
  height: $main-padding * 2;
  position: relative;
  cursor: pointer;
  .hamburger-inner {
    @extend %hamburger-bar;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: $transition-time background-color;

    &:before,
    &:after {
      content: "";
      @extend %hamburger-bar;
      transition: $transition-time transform;
    }

    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }
  }

  &.toggled {
    .hamburger-inner {
      background-color: transparent;

      &:before {
        transform-origin: 50% 50%;
        transform: rotate(-45deg);
        background-color: $color-text-light;
        top: 0;
      }

      &:after {
        transform-origin: 50% 50%;
        transform: rotate(45deg);
        background-color: $color-text-light;
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: none;
  }
}
