@import "../../../config/variables";

.about-hero {
  padding-top: $logo-height + $main-padding * 2;
  padding-bottom: $main-padding;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;

  .hero-description {
    margin-bottom: $main-padding;
  }

  .hero-background {
    display: none;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    min-height: 70vh;

    .hero-background {
      position: absolute;
      min-height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      display: block;
    }

    .hero-description {
      margin-left: $main-padding;
    }
    .hero-content {
      align-items: flex-start;
    }
  }
}
