@import "../../../config/variables";

.project-tile {
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: $min-tile-height;
  margin-bottom: $main-padding * 2;

  &:hover {
    .project-tile-content {
      opacity: 1;
    }
  }
}

.project-tile-content {
  position: absolute;
  padding: $main-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba($color-secondary, 0.9);
  opacity: 1;
  transition: opacity $transition-time;
  word-break: break-word;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    opacity: 0;
    height: 100%;
    top: 0;
    transform: none;
  }

  .project-title {
    font-weight: 700;
    font-size: 1.1rem;
    color: $color-text-dark;
  }

  .project-client {
    color: $color-text-dark;
  }

  .client-arrow {
    margin-left: $main-padding / 4;
    font-size: 0.8rem;
  }
}
