@import "../../../config/variables.scss";

.project-category {
  font-weight: 400;
  padding: $main-padding / 2 $main-padding;
  border-radius: $main-padding;
  flex: 0 1 100%;

  &:link {
    color: $color-text-dark;
  }

  &:visited {
    color: $color-text-dark;
  }

  &:hover {
    color: lighten($color-text-dark, 10%);
    text-decoration: underline;
  }

  &:active {
    color: $color-primary;
  }

  &.active {
    color: $color-primary;
    font-weight: 700;
    background-color: $color-project-category;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex: 0 1 auto;
  }
}
