@import "../../../config/variables";

.scroll-top-container {
  text-align: center;
  padding-top: $main-padding;
}

.scroll-top {
  border: none;
  padding: $main-padding / 2;
  color: $color-text-dark;
  background: transparent;
  outline: none;
  cursor: pointer;
}
