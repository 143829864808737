@import "../../../config/variables";

.top-header {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;

  &.static {
    position: relative;
  }
}

.top-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: $main-padding 0;
  position: relative;
}
