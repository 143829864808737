@import "../../../config/variables";

.footer {
  padding: $main-padding * 3 0;
  padding-top: $main-padding * 4;
}
.footer-section {
  margin-bottom: $main-padding;
}

.footer-section-title {
  font-size: 0.9rem;
  margin-bottom: $main-padding;
  text-transform: uppercase;
}
