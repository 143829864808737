@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap&subset=latin-ext");
@import "./variables";

html {
  font-size: 14px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

h3 {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: $main-padding / 2;
}

a {
  text-decoration: none;
  font-weight: 400;
  transition: $transition-time color;
  &:link {
    color: $color-primary;
  }

  &:visited {
    color: $color-primary;
  }

  &:hover {
    color: lighten($color-primary, 10%);
  }

  &:active {
    color: $color-primary;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

p {
  margin-bottom: $main-padding;
}
