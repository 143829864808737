@import "../../../config/variables";

.brand-logo {
  flex: 1;
  height: $logo-height;

  img {
    height: 100%;
    max-width: 100%;
  }
}
