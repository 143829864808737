@import "../../../config/variables";

.navigation {
  //   display: none;
  transition: $transition-time all;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: $main-padding 0;
    white-space: nowrap;
    overflow: hidden;
    transition: $transition-time all;
  }

  li {
    a {
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      padding: $main-padding / 2 $main-padding / 4;
      margin: 0 $main-padding / 4;
      display: block;

      &:link {
        color: $color-text-dark;
      }

      &:visited {
        color: $color-text-dark;
      }

      &:hover {
        color: lighten($color-text-dark, 10%);
      }

      &:active {
        color: $color-primary;
      }

      &.active {
        font-weight: 700;
        color: $color-primary;
      }
    }
  }

  @media screen and (max-width: map-get($breakpoints, sm) - 1) {
    position: absolute;
    width: 0;
    height: 100vh;
    top: 0;
    right: -$main-padding;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.5);
    background-color: darken($color-primary, 20%);
    ul {
      padding-top: $main-padding * 5;
      opacity: 0;
    }

    li {
      a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 400;
        padding: $main-padding / 2 $main-padding / 4;
        margin: 0 $main-padding / 4;
        display: block;

        &:link {
          color: $color-text-light;
        }

        &:visited {
          color: $color-text-light;
        }

        &:hover {
          color: lighten($color-text-light, 10%);
        }

        &:active {
          color: $color-text-light;
        }

        &.active {
          font-weight: 700;
          color: $color-text-light;
        }
      }
    }

    &.toggled {
      display: block;
      width: 50vw;
      ul {
        padding-left: $main-padding * 2;
        padding-right: $main-padding;
        opacity: 1;
        width: auto;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: block;

    ul {
      flex-direction: row;
    }
  }
}
