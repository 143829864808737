@import "../../../config/variables.scss";

.social-buttons {
  display: flex;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: $color-text-dark;
  color: $color-text-light;
  border-radius: 50%;
  width: $main-padding * 2;
  height: $main-padding * 2;
  margin-right: $main-padding / 2;
  font-size: 1.5rem;
  transition: $transition-time background-color;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $color-text-light;
  }

  &:hover {
    background-color: lighten($color-text-dark, 20%);
  }
}
