@import "../../../config/variables.scss";

.hero-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: $main-padding * 2;
  line-height: 1.2;
  display: inline-block;
  white-space: pre-wrap;

  .hero-title-content {
    box-shadow: inset 0 -0.175em #fff;
  }

  &.accent {
    .hero-title-content {
      box-shadow: inset 0 -0.175em $color-secondary;
    }
  }

  &.project {
    margin-top: $main-padding * 2;
    margin-bottom: 0;
    .hero-title-content {
      color: #fff;
      box-shadow: inset 0 -0.175em $color-secondary;
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    font-size: 4rem;
    &.hero-title-project {
      margin-top: $main-padding * 5;
    }
  }
}
