@import "../../../../config/variables.scss";

.member {
  display: flex;
  margin-bottom: $main-padding * 2;
  flex-direction: column;

  .member-photo {
    flex: 0 0 33%;

    img {
      width: 100%;
    }
  }

  .member-info {
    display: flex;
    flex-direction: column;
  }

  .member-name {
    display: flex;
    flex-direction: column;
  }

  .member-about {
    margin-top: $main-padding;
    text-align: justify;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    flex-direction: row;
    margin-bottom: $main-padding * 5;

    .member-info {
      padding: 0 $main-padding * 3 0;
    }

    .member-about {
      margin-top: $main-padding * 3;
    }

    &.alternate {
      flex-direction: row-reverse;
      .member-info {
        text-align: right;
      }
    }
  }
}
