$color-primary: #1100ca;
$color-secondary: #f8e001;
$color-gold: #f7b004;

$color-text-dark: #20211b;
$color-text-light: #fffffe;

$color-project-category: #e6e8e0;

$max-width: 1200px;
$main-padding: 20px;

$min-tile-height: 250px;

$transition-time: 0.3s;

$hamburger-bar-height: 2px;
$logo-height: 100px;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 920px,
  lg: 1200px,
  xl: 1368px
);
