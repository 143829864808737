@import "../../../config/variables";

.project-hero {
  background-color: rgba($color-primary, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: $main-padding 0;

  .hero-subtitle {
    display: block;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 500;
    color: #fff;
    margin-top: $main-padding / 2;
    margin-bottom: $main-padding * 2;
    white-space: pre-wrap;
  }
  .hero-description {
    margin-bottom: $main-padding;
  }

  .hero-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    display: block;
    background-size: cover;
    background-position: center;
  }

  .video-container {
    position: absolute;
    top: 50%;
    left: -100%;
    width: 300%;
    height: 100%;
    transform: translateY(-50%);
    padding-bottom: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding: $main-padding * 2 0;

    .hero-content {
      align-items: flex-start;
    }

    .project-info {
      padding-left: $main-padding * 4;
    }

    .hero-subtitle {
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: $main-padding * 5;
    }
  }
}
