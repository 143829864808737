@import "../../../config/variables";

.competences-hero {
  background-color: $color-secondary;
  padding-top: $logo-height + $main-padding * 2;
  padding-bottom: $main-padding;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .hero-description {
    margin-bottom: $main-padding;
  }

  .hero-background {
    display: none;
  }

  .competence-indicator-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: $max-width;
    width: 100%;
  }

  .competence-indicator {
    display: none;
    width: 0;
    height: 0;
    border-left: $main-padding * 3 solid transparent;
    border-right: $main-padding * 3 solid transparent;

    border-bottom: $main-padding * 3 solid #fff;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    transition: left $transition-time;
    &.competence-1 {
      left: 12.5%;
    }
    &.competence-2 {
      left: 37.5%;
    }
    &.competence-3 {
      left: 62.5%;
    }
    &.competence-4 {
      left: 87.5%;
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    min-height: 100vh;
    background-color: rgba($color-secondary, 0.75);

    .hero-background {
      position: absolute;
      min-height: 100%;
      min-width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      display: block;
    }

    .hero-content {
      align-items: flex-start;
      margin-bottom: $main-padding * 4;
    }
  }

  @media screen and (min-width: map-get($breakpoints, md)) {
    .competence-indicator {
      display: block;
    }
  }
}
