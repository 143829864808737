@import "../config/variables";

$gutter: $main-padding;

@mixin grid($cols) {
  $base: 100% / $cols;
  @for $i from 1 through $cols {
    .col-#{$i} {
      flex: 0 1 $base * $i;
    }
  }
  @each $res in xs, sm, md, lg {
    @media screen and (min-width: map-get($breakpoints, $res)) {
      @for $i from 1 through $cols {
        .col-#{$res}-#{$i} {
          flex: 0 1 $base * $i;
        }
      }
    }
  }
}

.row {
  margin-left: -$gutter/4;
  margin-right: -$gutter/4;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }

  [class*="col-"] {
    flex: 0 1 100%;
    padding-left: $gutter/4;
    padding-right: $gutter/4;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: map-get($breakpoints, sm)) {
      padding-left: $gutter;
      padding-right: $gutter;
    }

    &.vertical {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @include grid(12);
}
