@import "../../../config/variables.scss";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: #fff;
  opacity: 1;
  transition: opacity 1.5s;

  .indicator {
    width: 0;
    height: 100vh;
    background-color: $color-secondary;
  }

  .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 700;
  }

  &.fading {
    opacity: 0;
  }
}
