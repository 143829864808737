@import "../../../config/variables.scss";

.competence-name {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: $main-padding;

  &.gold {
    color: $color-gold;
  }
  &.primary {
    color: $color-primary;
  }
}

.competence-description {
  max-width: $max-width / 1.5;
  margin: 0 auto;

  &.bind {
    background-color: #fff;
    margin: $main-padding (-1 * $main-padding);
    margin-top: 0;
    padding: $main-padding;

    @media screen and (min-width: map-get($breakpoints, sm)) {
      display: none;
    }
  }
}
