@import "../../../config/variables";

.project-description {
  max-width: $max-width / 1.5;

  &:last-of-type {
    margin-bottom: $main-padding * 3;
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding-left: $main-padding * 4;
  }
}
